import React, { FC } from "react";
import { graphql } from "gatsby";

import { ContactPageQuery } from "lib/cms";

import PageWrapper from "components/PageWrapper";
import NotFoundPage from "./404";
import MetaData from "components/MetaData";

interface Props {
  data: ContactPageQuery;
  location: Location;
}

const ContactPage: FC<Props> = ({ data: { page } }) => {
  return page ? (
    <PageWrapper backgroundColor={page.backgroundColor}>
      <MetaData title="Contact" />
      <ul>
        {page.people.map(person => (
          <li key={person._id} className="mb-2.5 md:mb-9">
            <h2 className="font-bold">
              {person.name}
              {person.title && person.title.length ? `, ${person.title}` : ""}
            </h2>
            <a href={`mailto:${person.email}`}>{person.email}</a>
          </li>
        ))}
      </ul>
    </PageWrapper>
  ) : (
    <NotFoundPage />
  );
};

export const query = graphql`
  query ContactPage {
    page: sanityContact {
      backgroundColor
      people {
        name
        title
        email
      }
    }
  }
`;

export default ContactPage;
